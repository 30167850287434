/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { formatDeviceName } from "../utils";
import useUIText from "../hooks/translation";

const AutoSelectDistributors = ({
	items,
	userInput,
	setUserInput,
	setExampleQuestions,
	showAsterisk = false,
}) => {
	const uiText = useUIText();
	const [manufacturers, setManufacturers] = useState([]);
	const [devices, setDevices] = useState([]);
	const [selectedType, setSelectedType] = useState(null);
	const [selectedManufacturer, setSelectedManufacturer] = useState(null);
	const [selectedDevice, setSelectedDevice] = useState(null);

	const types =
		items
			.map((item) => item.type)
			.map((item) => ({ value: item, label: item })) || [];

	useEffect(() => {
		if (selectedType) {
			const manufacturers = items
				.find((item) => item.type === selectedType.value)
				.manufacturers.map((item) => item.manufacturer)
				.map((item) => ({ value: item, label: item }));
			setManufacturers(manufacturers);
			setSelectedManufacturer(null); // Reset selected manufacturer when type changes
			setDevices([]); // Reset devices when type changes
			setSelectedDevice(null); // Reset selected device when type changes
			setUserInput({ ...userInput, device: "" });
		} else {
			setManufacturers([]);
			setSelectedManufacturer(null);
			setDevices([]);
			setSelectedDevice(null);
			setExampleQuestions([]);
		}
	}, [items, selectedType]);

	useEffect(() => {
		if (selectedType && selectedManufacturer) {
			const manufacturer = items
				.find((item) => item.type === selectedType.value)
				.manufacturers.find(
					(item) => item.manufacturer === selectedManufacturer.value
				);
			if (manufacturer) {
				const devices = manufacturer.devices.map((item) => ({
					value: item.deviceName,
					label: formatDeviceName(item.deviceName),
				}));
				setDevices(devices);
				setSelectedDevice(null); // Reset selected device when manufacturer changes
				setUserInput({
					...userInput,
					device: "",
					company: manufacturer.companyName,
				});
				setExampleQuestions([]);
			} else {
				setDevices([]);
				setSelectedDevice(null);
				setUserInput({ ...userInput, device: "" });
				setExampleQuestions([]);
			}
		} else {
			setDevices([]);
			setSelectedDevice(null);
			setExampleQuestions([]);
		}
	}, [items, selectedManufacturer, selectedType]);

	return (
		<>
			<label htmlFor="type">{uiText?.form?.deviceType} {showAsterisk && <span>*</span>}</label>
			<Select
				className="basic-single"
				classNamePrefix="select"
				isClearable
				isSearchable
				options={types}
				placeholder={uiText?.form?.deviceTypePlaceholder}
				value={selectedType}
				onChange={(value) => {
					setSelectedType(value);
					setUserInput({ ...userInput, device: "" });
				}}
			/>
			<label htmlFor="manufacturer">
				{uiText?.form?.manufacturer} {showAsterisk && <span>*</span>}
			</label>
			<Select
				className="basic-single"
				classNamePrefix="select"
				isClearable
				isSearchable
				options={manufacturers}
				placeholder={
					manufacturers.length === 0
						? uiText?.form?.manufacturerPlaceholder1
						: uiText?.form?.manufacturerPlaceholder2
				}
				value={selectedManufacturer}
				onChange={(value) => {
					setSelectedManufacturer(value);
					setUserInput({ ...userInput, device: "" });
				}}
				isDisabled={manufacturers.length === 0}
			/>
			<label htmlFor="device">
				{uiText?.form?.deviceModel} {showAsterisk && <span>*</span>}
			</label>
			<Select
				className="basic-single"
				classNamePrefix="select"
				isClearable
				isSearchable
				options={devices}
				placeholder={
					devices.length === 0
						? uiText?.form?.deviceModelPlaceholder1
						: uiText?.form?.deviceModelPlaceholder2
				}
				value={selectedDevice}
				onChange={(value) => {
					setSelectedDevice(value);
					setUserInput({ ...userInput, device: value?.value || "" });
					setExampleQuestions(
						items
							.find((item) => item.type === selectedType.value)
							.manufacturers.find(
								(item) => item.manufacturer === selectedManufacturer.value
							)
							.devices.find((item) => item.deviceName === value.value)
							.exampleQuestions
					);
				}}
				isDisabled={devices.length === 0}
			/>
		</>
	);
};

export default AutoSelectDistributors;
